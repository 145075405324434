<template>
  <div class="extration-bdd-frais">
    <b-button
      size="sm"
      class="button-export-style  ml-2"
      @click="openModal"
      v-if="computedSelectedValidids.length"
    >
      <font-awesome-icon icon="envelope"
    /></b-button>
    <VusialisationEmailModal
      :type="type"
      :dataToUse="dataToUse"
      :isOpen="isOpen"
      :computedColumnIdProject="computedColumnIdProject"
      @custumChange="closeModal"
      @handleSendMail="handleSendMail"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    type: { required: true },
    dataToUse: { required: true }
  },
  components: {
    VusialisationEmailModal: () => import('./VusialisationEmailModal.vue')
  },
  data() {
    return {
      error: null,
      isOpen: false,
      validIds: []
    };
  },
  methods: {
    ...mapActions([
      'uploadGlobalTemplateInServieVisionFiliale',
      'getGlobalTemplateAndFiledsDataToUpdate',
      'setDaraTemplateUploded',
      'sendMailGestionGlobalFrais'
    ]),
    openModal() {
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
    },

    async handleSendMail() {
      var bodyFormData = new FormData();
      let dataToUse = this.dataToUse.filter(k => {
        return k.projects.some(e => e.check == true);
      });
      // dataToUse = dataToUse.sort((a, b) => a.nom.localeCompare(b.nom));
      let indexToUse = 0;
      for (let j = 0; j < dataToUse.length; j++) {
        for (let i = 0; i < dataToUse[j].projects.length; i++) {
          if (this.type != null) {
            if (
              dataToUse[j].projects[i].check == true &&
              dataToUse[j].projects[i][this.computedColumnIdProject] &&
              dataToUse[j].projects[i][this.computedColumnIdProject] != null
            ) {
              bodyFormData.append(
                'ids[' + [indexToUse] + ']',
                dataToUse[j].projects[i][this.computedColumnIdProject]
              );
            }
          }
          indexToUse = indexToUse + 1;
        }
      }

      const response = await this.sendMailGestionGlobalFrais(bodyFormData);
      if (response.succes) {
        this.getErrorMailing.map(element => {
          if (element.societe.length > 0) {
            this.flashMessage.show({
              status: 'error',
              title: element.message,
              clickable: true,
              time: 0,
              message: element.societe
            });
          }
        });
        if (this.getSuccessSendMails?.societe?.length > 0) {
          this.flashMessage.show({
            status: 'success',
            title: this.getSuccessSendMails?.message,
            clickable: true,
            time: 0,
            message: this.getSuccessSendMails?.societe
          });
        }
      } else {
        this.flashMessage.show({
          status: 'error',
          title: 'ERROR',
          clickable: true,
          time: 0,
          message: this.getOtherErrorMail
        });
      }
    }
  },
  computed: {
    ...mapGetters([
      'getTemplateFilialeToUpload',
      'getLoadingGetDataTemplate',
      'getErrorTemplateDataToUpploadeed',
      'getSuccessSendMails',
      'getErrorMailing',
      'getErrorInvalidElmail',
      'getErrorHasntEmail',
      'getOtherErrorMail',
      'getErrorHasentTemplateGlobal',
      'getErrorHasentTemplateEmailGlobal'
    ]),
    computedSelectedValidids() {
      let dataToUse = this.dataToUse.filter(k => {
        return k.projects.some(
          e => e.check == true && e[this.computedColumnIdProject] != null
        );
      });
      return dataToUse;
    },
    computedColumnIdProject() {
      switch (this.type) {
        case 'NOVA':
          return 'operationnel_id';
        case 'CACTUS':
          return 'commercial_id';
        case 'AXELIUS':
          return 'marketing_id';
        case 'ISO 33':
          return 'technique_id';
        case 'CERTILIA':
          return 'rge_id';
        case 'GE CDG':
          return 'CDG_id';
        case 'SAMI-B':
          return 'administratif_sav_id';
        case 'WEB ABONDANCE':
          return 'dsi_id';
        case 'CORPEDYS':
          return 'juridique_id';
        case 'ALTEO France':
          return 'alger_id';
        case 'NOVAE MAIRIE':
          return 'mairie_id';
        case 'GSE':
          return 'maintenance_pac_id';
        case 'UPCUBE TECHNOLOGIES':
          return 'upcube_id';
        case 'Alteo contrôle qualité':
          return 'alteo_control_gestion_id';
        default:
          return this.type;
      }
    }
  },
  mounted() {}
};
</script>

<style scoped lang="scss"></style>
<style lang="scss">
.align-item-extract-modal {
  display: flex;
  .form-group {
    width: 50%;
  }
}
.modal-extaction-bdd-frais {
  .d-block,
  #fieldset-horizontal,
  #fieldset-horizontal-type {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-align: start;
    color: #2a2a2a;
    margin-left: 3%;
    margin-top: 8px;
    margin-bottom: 1px;
  }
  .form-modal-custom-style {
    text-align: center;
  }
}
</style>
